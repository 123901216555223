<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Enquiries', '/network-enquiry'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div>
        Enquiries
        <info-popup
          :id="'enquiries:index'"
          :info_message="'As customers make an enquiry via your website designer they will land here, click an enquiry for more details of the customers request.'"
        ></info-popup>
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for enquiry..." />
    </portal>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <animation-staggered-fade appear name="fade-in">
        <div key="quotes_list" class="bg-white p-3 md:p-10 pb-6 border" data-index="1">
          <table class="table-lined xs:table-unstacked _with-hover w-full">
            <thead>
              <tr>
                <th class="w-10">#</th>
                <th class="w-16">Source</th>
                <th>Date</th>
                <th class="xs:hidden lg:table-cell">Name</th>
                <th>Postcode</th>
                <th>Email</th>
                <th class="xs:hidden lg:table-cell">Phone</th>
                <th>
                  Status
                  <span class="cursor-pointer inline-block">
                    <span class="inline-block bg-gray-400 rounded p-1 text-white" @click="status_filter_dropdown_open = !status_filter_dropdown_open">
                      <i class="fal fa-filter mx-1"></i>
                      <span v-if="statuses.length > 0 && statusFilter != 0">
                        {{ statuses.filter((x) => x.value == statusFilter)[0].description }}
                      </span>
                      <i v-if="status_filter_dropdown_open" class="fal fa-chevron-up mx-1"></i>
                      <i v-else class="fal fa-chevron-down mx-1"></i>
                      <info-popup
                        :id="'quotes:status'"
                        :info_message="'Click the status button to see the status of all quotes and filter them to suit.'"
                        :next_id="'quotes:pricebook'"
                      ></info-popup>
                    </span>
                    <div v-show="status_filter_dropdown_open" class="relative">
                      <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded right-0 mt-1" style="min-width: 175px">
                        <div
                          class="p-1 border-b border-gray-300 font-medium flex justify-between m-2"
                        >
                          <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">
                            Filter Status
                          </div>
                          <div class="flex flex-col justify-around">
                            <i
                              class="fal fa-times"
                              @click.stop="setStatusFilter(0)"
                            ></i>
                          </div>
                        </div>
                        <div class="mt-2">
                          <div
                            v-for="(status, index) in statuses"
                            :key="index"
                            class="flex gap-2 items-center py-2 px-4 hover:bg-gray-200"
                            @click="setStatusFilter(status.value)"
                          >
                            <div class="rounded-full w-2 h-2" :class="statusClass(status.value)"></div>
                            <span v-text="status.description"></span>
                            <span v-show="statusFilter == status.value" class="text-green-600">
                              <i class="fal fa-check"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </th>
                <th class="w-16">
                  <button class="btn-action" @click.stop="loadFeed">Reload</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <router-link
                v-for="quote in quotes"
                :key="quote.id"
                tag="tr"
                :to="quote.url"
                :class="{ _hovered: hovered === quote.id }"
                @mouseover.native="hovered = quote.id"
                @mouseleave.native="hovered = null"
              >
                <td>
                  <div class="td-title w-24">Number:</div>
                  <div class="td-content">{{ quote.customerCounter }}</div>
                </td>
                <td>
                  <div class="td-title w-24">Source:</div>
                  <div
                    :title="quote.fromDealer ? 'Dealer Network' : 'Branded Mode'"
                  >
                    <img
                      v-if="quote.fromDealer"
                      src="/images/dealer-network-icon.png"
                      class="w-8 m-auto"
                    />
                    <img
                      v-else
                      :src="store_url + 'images/icons/Br.png'"
                      class="w-8 m-auto"
                    />
                  </div>
                </td>
                <td>
                  <div class="td-title w-24">Date:</div>
                  <div class="td-content">{{ formatDate(quote.date) }}</div>
                </td>
                <template v-if="quote.consumer">
                  <td class="xs:hidden lg:table-cell">
                    <div class="td-title w-24">Name:</div>
                    <div class="td-content">
                      {{ quote.consumer.firstName }} {{ quote.consumer.lastName }}
                    </div>
                  </td>
                  <td>
                    <div class="td-title w-24">Postcode:</div>
                    <div class="td-content">{{ quote.consumer.addressCode }}</div>
                  </td>
                  <td>
                    <div class="td-title w-24">Email:</div>
                    <div class="td-content truncate xs:w-24 md:w-auto">
                      {{ quote.consumer.email }}
                    </div>
                  </td>
                  <td class="xs:hidden lg:table-cell">
                    <div class="td-title w-24">Phone:</div>
                    <div class="td-content">{{ quote.consumer.mobile }}</div>
                  </td>
                </template>
                <template v-else>
                  <td class="xs:hidden lg:table-cell">
                    <div class="td-title w-24">Name:</div>
                    <div class="td-content">-</div>
                  </td>
                  <td>
                    <div class="td-title w-24">Postcode:</div>
                    <div class="td-content">-</div>
                  </td>
                  <td>
                    <div class="td-title w-24">Email:</div>
                    <div class="td-content">-</div>
                  </td>
                  <td class="xs:hidden lg:table-cell">
                    <div class="td-title w-24">Phone:</div>
                    <div class="td-content">-</div>
                  </td>
                </template>
                <td>
                  <div class="td-title w-24">Status:</div>
                  <div class="td-content whitespace-no-wrap truncate xs:w-24 md:w-auto">
                    <div
                      class="inline-block rounded-full w-2 h-2 mr-2"
                      :class="statusClass(quote.enquiryStatus)"
                    ></div>
                    <span v-text="formatStatus(quote.enquiryStatus)"></span>
                  </div>
                </td>
                <td class="td-action-buttons">
                  <div class="table-edit-btn">
                    <i class="fa fa-pencil"></i>
                  </div>
                </td>
              </router-link>
            </tbody>
          </table>
          <div v-if="quotes.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
            <div class="flex justify-between">
              <div>No Enquiries to show</div>
              <div v-if="$route.query.search">
                <a class="btn-action" @click.stop="$router.push({ query: undefined }), loadFeed()">
                  Clear Search
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="quotes.length > 0" key="pagination" class="mt-3">
          <pagination :total_items="total_items" :limit="limit" @load-feed="loadFeed"></pagination>
        </div>
      </animation-staggered-fade>
    </loading>
  </touch-layout>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';
import Pagination from '@/components/shared/Pagination.vue';
import currencyHelper from '@/mixins/currencyHelper';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    Breadcrumbs,
    pagination: Pagination,
    SearchBox,
  },
  mixins: [dateHelper, currencyHelper],
  data() {
    return {
      quotes: [],
      loading: true,
      limit: 10,
      total_items: 0,
      statuses: [],
      status_filter_dropdown_open: false,
      hovered: null,
      isDelay: true,
    };
  },
  computed: {
    statusFilter() {
      return this.$route.query.statusFilter || 0;
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.statuses = await this.$store.dispatch('touch/getEnquiryStatuses');
  },
  methods: {
    formatStatus(statusId) {
      const matchingStatus = this.statuses.filter((status) => status.value === statusId)[0];
      return matchingStatus ? matchingStatus.description : 'Not Found';
    },
    statusClass(statusId) {
      switch (statusId) {
        case 0:
          return 'bg-gray-300';
        case 1:
          return 'bg-green-600';
        case 2:
          return 'bg-touch-purple';
        case 3:
          return 'bg-touch-orange';
        case 4:
          return 'bg-touch-red';
        default:
          return 'bg-white border';
      }
    },
    delaySearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loadFeed();
      }, 300);
    },
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.$store.dispatch('touch/loadEnquiries', {
        limit: this.limit,
        offset,
        statusId: this.statusFilter,
        search: this.$route.query.search,
      });
      this.quotes = response.enquiries;
      this.total_items = response.total_items;
      this.loading = false;
    },
    setStatusFilter(statusFilter) {
      this.$router
        .push({ query: { ...this.$route.query, statusFilter: statusFilter || undefined } })
        .catch(() => {});
      this.status_filter_dropdown_open = false
    },
  },
};
</script>
